import House from '@/public/house.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

type Props = {
  transaction: any;
  isSelected: boolean;
  onClick: (data: any) => void;
};
function Transaction({ transaction, isSelected, onClick }: Props) {
  return (
    <Card
      onClick={() => onClick(transaction)}
      className="hover"
      variant="line"
      elevation={isSelected ? 1 : 0}
    >
      <CardMedia image={House.src} />
      <CardContent>
        <Typography variant="h6">
          {transaction?.property?.propertyName}
        </Typography>
        <Typography variant="body2">
          {transaction?.property?.propertyAddress}
        </Typography>
        <Chip
          label={transaction.type === 'buy' ? 'Buying' : 'Selling'}
          color="default"
          size="small"
          variant="filled"
        />
      </CardContent>
    </Card>
  );
}

export default Transaction;
