import { useTask } from '@/context/TaskContextProvider';
import UserPlaceholderImg from '@/public/user-placeholder.png';
import { Check, ChevronLeft, Delete } from '@mui/icons-material/';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

type Props = {
  participant: any;
  currentUser: boolean;
};

function CreateTransactionParticipant({ participant, currentUser }: Props) {
  const {
    state: { formValues },
    dispatch,
  } = useTask();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleRemove = (participant: any) => {
    const updatedParticipants = formValues.participants.filter(
      (p: any) => p.email !== participant.email
    );
    dispatch({
      type: 'SET_FORM_VALUES',
      payload: { participants: updatedParticipants },
    });
  };

  const ParticipantDialog = () => {
    return (
      <Dialog open={showDialog}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you remove {participant.firstName}, the only way they can rejoin
            your transaction is if you invite them again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            endIcon={<ChevronLeft />}
            onClick={() => {
              setShowDialog(false);
            }}
            data-testid="no"
          >
            No, go back
          </Button>
          <Button
            size="medium"
            color="error"
            endIcon={<Delete />}
            onClick={() => handleRemove(participant)}
            data-testid="yes"
          >
            Yes, Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Box
        sx={{
          width: { xs: '100%', sm: '200px' },
          height: { xs: 'fit-content', sm: '256px' },
          minHeight: { xs: '138px', sm: '256px' },
        }}
      >
        <Card variant="info">
          <CardHeader
            avatar={
              <CardMedia
                component="img"
                image={UserPlaceholderImg.src}
              ></CardMedia>
            }
            action={
              !currentUser ? (
                <IconButton
                  size="medium"
                  color="error"
                  onClick={() => setShowDialog(true)}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null
            }
          />
          <CardContent>
            <Typography variant="h6">{`${participant.firstName} ${
              participant.lastName
            } ${currentUser ? '(me)' : ''}`}</Typography>
            <Typography variant="body2"></Typography>
            <Chip
              label="Added"
              size="small"
              icon={<Check />}
              sx={{ display: { xs: 'none', md: 'block' } }}
            />
          </CardContent>
        </Card>
      </Box>
      <ParticipantDialog />
    </>
  );
}

export default CreateTransactionParticipant;
