'use client';

import Splash from '@/components/_common/Splash/Splash';
import { useTask } from '@/context/TaskContextProvider';
import PurchaseImage from '@/public/transactionPurchase.svg';
import SaleImage from '@/public/transactionSale.svg';
import { CREATE_TRANSACTION_WITH_PARTICIPANTS } from '@/services/mutations';
import { GET_USER } from '@/services/queries';
import { wait } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CreateTransactionAddParticipant from './components/AddParticipant';
import CreateTransactionAddParticipants from './components/AddParticipants';
import CreateTransactionAddress from './components/Address';
import CreateTransactionType from './components/TransactionType';

type Props = {
  transactionType?: 'purchase' | 'sale' | null;
  line1?: string | null;
  line2?: string | null;
  town?: string | null;
  postcode?: string | null;
  uprn?: string | null;
  transactionAddress?: {
    // remove when done updating in BE
    line1: string | null;
    line2?: string | null;
    town: string | null;
    postcode: string | null;
    uprn: string | null;
  } | null;
  onClose?: (data?: any) => void;
  type?: 'default' | 'fromInvite';
};

function CreateTransaction({
  transactionType = null,
  line1,
  line2,
  town,
  postcode,
  uprn = null,
  transactionAddress = null, // remove when done updating in BE
  onClose,
  type = 'default',
}: Props) {
  const {
    state: { activeStep, isComplete, isSubmitting, formValues, waitDuration },
    dispatch,
  } = useTask();

  const { data: userData, loading } = useQuery(GET_USER);
  const [createTransactionWithParticipants] = useMutation(
    CREATE_TRANSACTION_WITH_PARTICIPANTS
  );

  const handleMutation = async () => {
    // delete fromCreateNewTransaction propety when performing mutation
    const { fromCreateNewTransaction, ...createTransactionFormValues } =
      formValues;
    const input = {
      transactionId: uuidv4(),
      ...createTransactionFormValues,
    };

    dispatch({ type: 'SET_IS_COMPLETE', payload: true });
    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

    createTransactionWithParticipants({
      variables: { input: input },
      onCompleted: async () => {
        await wait(waitDuration);
        dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
      },
    });
  };

  useEffect(() => {
    dispatch({ type: 'SET_TOTAL_STEPS', payload: 3 });

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        transactionType: transactionType || null,
        ...transactionAddress, // remove when done updating in BE
        line1: line1,
        line2: line2,
        town: town,
        postcode: postcode,
        uprn: uprn,
      },
    });

    if (userData)
      dispatch({
        type: 'SET_FORM_VALUES',
        payload: {
          participants: [
            {
              firstName: userData.user.givenName,
              lastName: userData.user.familyName,
              customerId: userData.user.userId,
              email: userData.user.email,
              phone: userData.user.phone,
              role: transactionType === 'purchase' ? 'buyer' : 'seller',
              inviteEmail: false,
              invitePhone: false,
            },
          ],
        },
      });

    if (transactionType !== null) {
      if (uprn !== null || transactionAddress?.uprn !== null) {
        dispatch({ type: 'SET_ACTIVE_STEP', payload: 3 });
        return;
      }
      dispatch({ type: 'SET_ACTIVE_STEP', payload: 2 });
      return;
    }

    dispatch({ type: 'SET_ACTIVE_STEP', payload: 1 });
  }, [userData]);

  if (loading)
    return <Splash title="Loading..." footer={null} isLoading={true} />;

  if (isComplete)
    return (
      <Splash
        title={
          isSubmitting ? 'Creating your transaction...' : 'Transaction created.'
        }
        footer={
          <Button
            variant={'contained'}
            disabled={isSubmitting}
            size="large"
            endIcon={isSubmitting ? <LockIcon /> : <ChevronRightIcon />}
            sx={{ width: '100%' }}
            onClick={onClose}
            data-testid="next"
          >
            Next
          </Button>
        }
        isLoading={isSubmitting}
        isSuccess={!isSubmitting}
        backgroundImage={transactionType === 'sale' ? SaleImage : PurchaseImage}
      />
    );

  return (
    <>
      {activeStep === 1 && <CreateTransactionType />}
      {activeStep === 2 && <CreateTransactionAddress />}
      {activeStep === 3 && (
        <CreateTransactionAddParticipants
          onSubmit={handleMutation}
          type={type}
        />
      )}
      {activeStep === 4 && (
        <CreateTransactionAddParticipant currentUser={userData} />
      )}
    </>
  );
}

export default CreateTransaction;
