'use client';

import { Form } from '@/components/_common/Form/Form';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '@/components/_common/Form/FormTypes';
import { useTask } from '@/context/TaskContextProvider';

function CreateTransactionType() {
  const {
    state: { formValues },
    dispatch,
  } = useTask();

  const components: DynamicFormComponent[] = [
    {
      componentId: 'transactionType',
      componentLabel: 'Are you buying or selling a property?',
      componentGuidance: null,
      componentInputs: [
        {
          name: 'transactionType',
          type: 'radio',
          label: null,
          options: [
            {
              id: 'purchase',
              label: 'Buying',
            },
            {
              id: 'sale',
              label: 'Selling',
            },
          ],
          config: {
            required: {
              value: true,
              message: 'Please select choose an option',
            },
          },
        },
      ],
    },
  ];

  const footer: DynamicFooterComponent[] = [
    {
      componentId: 'submit',
      componentInputs: [
        {
          name: 'submit',
          type: 'submit',
          label: 'Next',
          rank: 'primary',
          iconRight: 'chevron_right',
        },
      ],
    },
  ];

  return (
    <>
      <Form
        components={components}
        footerComponents={footer}
        formValues={formValues}
        onSubmit={(formData: any) =>
          dispatch({ type: 'NEXT', payload: formData })
        }
      />
    </>
  );
}

export default CreateTransactionType;
