import FormLayout from '@/components/_layout/Form/Form';
import { useTask } from '@/context/TaskContextProvider';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import CreateTransactionParticipants from './Participants';

type Props = {
  onSubmit: () => void;
  type: 'default' | 'fromInvite';
};

function CreateTransactionAddParticipants({
  onSubmit,
  type = 'default',
}: Props) {
  const {
    state: { formValues },
    dispatch,
  } = useTask();
  const [showDialog, setShowDialog] = useState(false);

  const Content = (
    <Stack sx={{ gap: 2, alignItems: 'center' }}>
      <Stack sx={{ gap: 1, maxWidth: '400px' }}>
        <Typography variant="h6">
          Add everyone who{' '}
          {formValues.transactionType === 'sale'
            ? 'owns this property'
            : 'is buying this property with you'}
        </Typography>
        <Typography variant="body1">
          Make sure you add all{' '}
          {formValues.transactionType === 'sale'
            ? 'executors, beneficial owners or directors if a company owns part of the property.'
            : 'beneficial owners or directors if a company is involved in this purchase.'}
        </Typography>
        <Typography variant="body1">
          Everyone will need to complete their own tasks to keep your{' '}
          {formValues.transactionType} on track.
        </Typography>
      </Stack>
      <CreateTransactionParticipants />
      <Dialog open={showDialog}>
        <DialogTitle>Is this everyone?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If there&#39;s people you&#39;re{' '}
            {formValues.transactionType === 'sale' ? 'selling' : 'buying'} with
            missing, not adding them now will slow your transaction down.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            endIcon={<ChevronLeft />}
            onClick={() => {
              setShowDialog(false);
            }}
            data-testid="add"
          >
            There&#39;s people missing
          </Button>
          <Button
            size="medium"
            color="error"
            endIcon={<ChevronRightIcon />}
            onClick={() => onSubmit()}
            data-testid="submit"
          >
            This is everyone
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );

  const Footer = (
    <Stack direction="row" spacing={1}>
      {type === 'default' && (
        <IconButton
          color="secondary"
          size="large"
          onClick={() => dispatch({ type: 'BACK' })}
          data-testid="back"
        >
          <ArrowBack />
        </IconButton>
      )}
      <Button
        variant="contained"
        size="large"
        type="submit"
        sx={{ width: '100%' }}
        onClick={() => setShowDialog(true)}
        endIcon={<ChevronRightIcon />}
        data-testid="show-confirmation"
      >
        Next
      </Button>
    </Stack>
  );

  return <FormLayout content={Content} footer={Footer} maxWidth={false} />;
}

export default CreateTransactionAddParticipants;
