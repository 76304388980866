'use client';

import Splash from '@/components/_common/Splash/Splash';
import { useAuth } from '@/context/AuthContextProvider';
import { useTask } from '@/context/TaskContextProvider';
import CompanyPlaceholderImg from '@/public/company-placeholder.png';
import HouseDetached from '@/public/house-detached-2.svg';
import { ADD_SELLING_ESTATE_AGENT_FROM_INVITE } from '@/services/mutations';
import { GET_INVITE } from '@/services/queries';
import { wait } from '@/utils/helpers';
import { useMutation, useQuery } from '@apollo/client';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import SelectTransaction from './components/SelectTransaction';

type Props = {
  inviteId: string;
  onClose?: (data?: any) => void;
};

function AddSellingEstateAgentFromInvite({ inviteId, onClose }: Props) {
  const { userId } = useAuth();
  const [companyImage, setCompanyImage] = useState(CompanyPlaceholderImg.src);
  const {
    state: {
      activeStep,
      isComplete,
      isSubmitting,
      isCanvasOpen,
      formValues,
      canvasContent,
      waitDuration,
    },
    dispatch,
  } = useTask();

  const { data, loading, refetch } = useQuery(GET_INVITE, {
    variables: { inviteId: inviteId, userId: userId },
    notifyOnNetworkStatusChange: true,
  });

  const [addSellingEstateAgentFromInvite, { loading: mutationLoading }] =
    useMutation(ADD_SELLING_ESTATE_AGENT_FROM_INVITE);

  const handleMutation = async (formData: any) => {
    const input = {
      transactionId: formData.transactionId,
      inviteId: formData.inviteId,
      // keys: formValues.keys,
      // board: formValues.board,
      // boardDetail: formValues.boardDetail,
      // interestedParties: formValues.interestedParties,
      // interestedPartiesDetail: formValues.interestedPartiesDetail,
      // conflict: formData.conflict,
      // conflictDetail: formData.conflictDetail,
    };

    dispatch({ type: 'SET_IS_COMPLETE', payload: true });
    dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

    addSellingEstateAgentFromInvite({
      variables: { input: input },
      onCompleted: async () => {
        await wait(waitDuration);
        dispatch({ type: 'SET_IS_SUBMITTING', payload: false });
      },
    });
  };

  useEffect(() => {
    if (!isCanvasOpen) {
      dispatch({ type: 'SET_ACTIVE_STEP', payload: 1 });
    }
  }, [isCanvasOpen]);

  useEffect(() => {
    dispatch({ type: 'SET_ACTIVE_STEP', payload: 1 });
  }, []);

  useEffect(() => {
    if (data) setCompanyImage(data.invite.company.logoUrl);
  }, [data]);

  if (isComplete && !isCanvasOpen)
    return (
      <Splash
        title={
          isSubmitting
            ? `Adding ${data.invite.invite.meta.companyName} to your team...`
            : `${data.invite.invite.meta.companyName} are now helping you sell ${data.invite.invite.meta.propertyName}`
        }
        content={
          <Image
            src={companyImage}
            priority={true}
            alt={`Company thumbnail`}
            width={186}
            height={186}
            style={{
              borderRadius: '16px',
              border: '2px solid white',
            }}
            onError={() => {
              setCompanyImage(CompanyPlaceholderImg.src);
            }}
          />
        }
        backgroundImage={HouseDetached}
        footer={
          <Button
            variant={'contained'}
            disabled={isSubmitting}
            size="large"
            endIcon={isSubmitting ? <LockIcon /> : <ChevronRightIcon />}
            sx={{ width: '100%' }}
            onClick={onClose}
            data-testid="next"
          >
            Next
          </Button>
        }
        isLoading={isSubmitting}
        isSuccess={!isSubmitting}
      />
    );

  if (isCanvasOpen) return canvasContent;

  return (
    <>
      {activeStep === 1 && (
        <SelectTransaction
          data={data}
          isLoading={loading}
          transactionType="sell"
          refetch={refetch}
          onSubmit={handleMutation}
        />
      )}
      {/* {activeStep === 2 && <Board data={data} />}
      {activeStep === 3 && <Keys data={data} />}
      {activeStep === 4 && <InterestedParties data={data} />}
      {activeStep === 5 && (
        <CompanyConflicts data={data} onSubmit={handleMutation} />
      )} */}
    </>
  );
}

export default AddSellingEstateAgentFromInvite;
