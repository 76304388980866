import FormLayout from '@/components/_layout/Form/Form';
import CreateTransaction from '@/components/_v2/CreateTransaction/CreateTransaction';
import { useTask } from '@/context/TaskContextProvider';
import { Add, ChevronRight, Close, Lock, Refresh } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import Loading from './Loading';
import MetaTransaction from './MetaTransaction';
import Transaction from './Transaction';

type Props = {
  data: any;
  transactionType: 'buy' | 'sell';
  isLoading: boolean;
  refetch: () => void;
  onSubmit: (data: any) => void;
};

const TransactionLoader = (component: any) => {
  return (
    <>
      <Skeleton
        variant="text"
        sx={{ width: '200px', maxWidth: '100%', height: '24px', mx: 3 }}
      />
      <Card variant="line" elevation={0}>
        <Skeleton
          variant="rounded"
          sx={{ width: '96px', height: '96px', aspectRatio: 1 }}
        />
        <CardContent>
          <Skeleton
            variant="text"
            sx={{ width: '100%', maxWidth: '100%', height: '20px' }}
          />
          <Skeleton
            variant="text"
            sx={{ width: '96px', maxWidth: '100%', height: '20px' }}
          />
        </CardContent>
      </Card>
    </>
  );
};

function SelectTransaction({
  data,
  transactionType = 'buy',
  isLoading,
  refetch,
  onSubmit,
}: Props) {
  const {
    state: { formValues },
    dispatch,
  } = useTask();

  const containerRef = useRef(null);

  const [view, setView] = useState<any>({
    headline: null,
    subheadline: null,
    currentSelectedTransactionId: null,
    showMetaTransaction: false,
    showRefresh: false,
    showCreateTransaction: false,
    showCreateNewTransaction: false,
    showPropertyIsWrong: true,
    confirmEnabled: false,
  });

  const [isScrollable, setIsScrollable] = useState(false);

  const handleTransactionSelect = (transaction: any) => {
    setView({
      ...view,
      currentSelectedTransactionId: transaction.transactionId,
      confirmEnabled: true,
    });
  };

  const handleCreateTransaction = () => {
    dispatch({
      type: 'SET_CANVAS_CONTENT',
      payload: (
        <CreateTransaction
          transactionType={transactionType === 'buy' ? 'purchase' : 'sale'}
          line1={data.invite.invite.meta.propertyLine1}
          line2={data.invite.invite.meta.propertyLine2}
          town={data.invite.invite.meta.propertyTown}
          postcode={data.invite.invite.meta.propertyPostcode}
          uprn={data.invite.invite.meta.uprn}
          onClose={() => {
            refetch();
            dispatch({ type: 'SET_IS_CANVAS_OPEN', payload: false });
            dispatch({ type: 'SET_IS_COMPLETE', payload: false });
          }}
          type="fromInvite"
        />
      ),
    });
    dispatch({ type: 'SET_IS_CANVAS_OPEN', payload: true });
  };

  const handleCreateNewTransaction = () => {
    dispatch({
      type: 'SET_CANVAS_CONTENT',
      payload: (
        <CreateTransaction
          onClose={() => {
            refetch();
            dispatch({ type: 'SET_IS_CANVAS_OPEN', payload: false });
            dispatch({ type: 'SET_IS_COMPLETE', payload: false });
            dispatch({
              type: 'SET_FORM_VALUES',
              payload: {
                line1: null,
                line2: null,
                town: null,
                postcode: null,
                uprn: null,
                fromCreateNewTransaction: true,
              },
            });
          }}
        />
      ),
    });
    dispatch({ type: 'SET_IS_CANVAS_OPEN', payload: true });
  };

  const handleShowTransactions = () => {
    const companyName = data.invite.invite.meta.companyName;

    if (data.invite.transactions && data.invite.transactions.length > 0)
      setView({
        headline: `Select the property that ${companyName} is helping you ${transactionType}`,
        subheadline: '',
        currentSelectedTransactionId: null,
        showMetaTransaction: false,
        showRefresh: true,
        showCreateTransaction: false,
        showCreateNewTransaction: true,
        showPropertyIsWrong: false,
        confirmEnabled: false,
      });
    setView({
      headline: `Select the property that ${companyName} is helping you ${transactionType}`,
      currentSelectedTransactionId: null,
      showMetaTransaction: false,
      showRefresh: true,
      showCreateTransaction: false,
      showCreateNewTransaction: true,
      showPropertyIsWrong: false,
      confirmEnabled: false,
    });
  };

  //set state based on different conditions on initial load
  useEffect(() => {
    if (data) {
      const companyName = data.invite.invite.meta.companyName;
      const propertyLine1 = data.invite.invite.meta.propertyLine1;
      const transactions = data.invite.transactions;
      const transactionMatchingUprn = (propertyLine1: string) => {
        if (transactions === null) return false;
        return transactions.find((transaction: any) => {
          return transaction?.property?.lineOne === propertyLine1;
        });
      };

      // Coming from Create new transaction and transaction exists
      if (formValues.fromCreateNewTransaction) {
        setView({
          headline: `Select the property that ${companyName} is helping you ${transactionType}`,
          subheadline: '',
          currentSelectedTransactionId: null,
          showMetaTransaction: false,
          showRefresh: true,
          showCreateTransaction: false,
          showCreateNewTransaction: true,
          showPropertyIsWrong: false,
          confirmEnabled: false,
        });
        return;
      }
      //Transaction doesn’t exist that matches invite property
      if (
        propertyLine1 &&
        !!!transactionMatchingUprn(propertyLine1) &&
        view.showPropertyIsWrong
      ) {
        setView({
          headline: `Set up the property that ${companyName} is helping you ${transactionType}`,
          subheadline: '',
          currentSelectedTransactionId: null,
          showMetaTransaction: true,
          showRefresh: false,
          showCreateTransaction: true,
          showCreateNewTransaction: false,
          showPropertyIsWrong: true,
          confirmEnabled: false,
        });
        return;
      }
      //Transaction exists that matches invite property
      if (
        propertyLine1 &&
        !!transactionMatchingUprn(propertyLine1) &&
        view.showPropertyIsWrong
      ) {
        setView({
          headline: `Confirm the property that ${companyName} is helping you ${transactionType}`,
          subheadline: 'This property matches your invite.',
          currentSelectedTransactionId:
            transactionMatchingUprn(propertyLine1).transactionId,
          showMetaTransaction: true,
          showRefresh: false,
          showCreateTransaction: false,
          showCreateNewTransaction: false,
          showPropertyIsWrong: true,
          confirmEnabled: true,
        });
        return;
      }
      //No property in invite or propertyIsWrong and no transactions exist
      if (
        (!propertyLine1 || !view.showPropertyIsWrong) &&
        transactions &&
        transactions.length === 0
      ) {
        setView({
          headline: `Set up the property that ${companyName} is helping you ${transactionType}`,
          subheadline: '',
          currentSelectedTransactionId: null,
          showMetaTransaction: false,
          showRefresh: true,
          showCreateTransaction: true,
          showCreateNewTransaction: false,
          showPropertyIsWrong: false,
          confirmEnabled: false,
        });
      }
      //No property in invite or propertyIsWrong and transactions exist
      if (
        (!propertyLine1 || !view.showPropertyIsWrong) &&
        transactions &&
        transactions.length > 0
      ) {
        setView({
          headline: `Select the property that ${companyName} is helping you ${transactionType}`,
          subheadline: '',
          currentSelectedTransactionId: null,
          showMetaTransaction: false,
          showRefresh: true,
          showCreateTransaction: false,
          showCreateNewTransaction: true,
          showPropertyIsWrong: false,
          confirmEnabled: false,
        });
      }
    }
  }, [data]);
  useEffect(
    () =>
      setIsScrollable(
        // @ts-ignore
        containerRef?.current?.offsetHeight >= window.innerHeight
      ),
    [containerRef, view]
  );

  const getIsSelected = (transaction: any) => {
    if (view.currentSelectedTransactionId)
      return view.currentSelectedTransactionId === transaction.transactionId;
    return false;
  };

  const LoadingContent = <Loading />;

  const Content = (
    <Stack sx={{ gap: 2 }} ref={containerRef}>
      <Typography variant="h6" px={2}>
        {view.headline}
      </Typography>
      <Paper elevation={0} sx={{ p: 1, borderRadius: 3 }}>
        <Stack sx={{ gap: 1 }}>
          <Typography variant="body1" sx={{ px: 2, pt: 1 }} color="primary">
            {view?.subheadline}
          </Typography>
          {isLoading ? (
            <TransactionLoader />
          ) : data ? (
            <Stack sx={{ gap: 1 }}>
              {view.showMetaTransaction ? (
                <MetaTransaction
                  meta={data.invite.invite.meta}
                  transactionType={transactionType}
                  isSelected={view.currentSelectedTransactionId !== null}
                />
              ) : (
                data.invite.transactions &&
                data.invite.transactions
                  .filter(
                    (tranasction: any) =>
                      tranasction.type ===
                      (transactionType === 'buy' ? 'purchase' : 'sale')
                  )
                  .map((transaction: any) => (
                    <Transaction
                      key={transaction.transactionId}
                      transaction={transaction}
                      isSelected={getIsSelected(transaction)}
                      onClick={handleTransactionSelect}
                    />
                  ))
              )}
            </Stack>
          ) : (
            <TransactionLoader />
          )}

          {view?.showRefresh && (
            <Button
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              endIcon={<Refresh />}
              onClick={() => refetch()}
              data-testid="refresh"
            >
              Refresh transactions
            </Button>
          )}
          {view?.showCreateTransaction && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              endIcon={<ChevronRight />}
              onClick={() => handleCreateTransaction()}
              data-testid="create"
            >
              Create transaction
            </Button>
          )}
          {view?.showCreateNewTransaction && (
            <Button
              size="large"
              color="secondary"
              type="submit"
              fullWidth
              endIcon={<Add />}
              onClick={() => handleCreateNewTransaction()}
              data-testid="create"
            >
              Create a new transaction
            </Button>
          )}
        </Stack>
      </Paper>
    </Stack>
  );

  const Footer = (
    <>
      <Stack direction="column" spacing={2}>
        {view?.showPropertyIsWrong && !isLoading && (
          <Button
            size="large"
            type="submit"
            fullWidth
            endIcon={<Close />}
            onClick={() => handleShowTransactions()}
            data-testid="wrong"
          >
            This property is wrong
          </Button>
        )}
        <Button
          variant="contained"
          size="large"
          type="submit"
          disabled={isLoading || !view.confirmEnabled}
          endIcon={
            isLoading || !view.confirmEnabled ? <Lock /> : <ChevronRight />
          }
          sx={{ width: '100%' }}
          data-testid="submit"
          onClick={() =>
            onSubmit({
              transactionId: view.currentSelectedTransactionId,
              inviteId: data.invite.invite.requestId,
            })
          }
        >
          Confirm
        </Button>
      </Stack>
    </>
  );

  if (isLoading) return <FormLayout content={LoadingContent} footer={Footer} />;

  return (
    <FormLayout content={Content} footer={Footer} isScrollable={isScrollable} />
  );
}

export default SelectTransaction;
