function Loading() {
  return (
    <svg
      width="369"
      height="259"
      viewBox="0 0 369 259"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="16.8948"
        width="320.959"
        height="20"
        fill="#00A69A"
        fill-opacity="0.05"
      />
      <rect
        x="16.8948"
        y="28"
        width="246.092"
        height="20"
        fill="#00A69A"
        fill-opacity="0.05"
      />
      <rect
        x="0.894775"
        y="56"
        width="368"
        height="203"
        rx="32"
        fill="#00A69A"
        fill-opacity="0.05"
      />
      <rect
        x="32.8948"
        y="80"
        width="246.092"
        height="16"
        fill="#00A69A"
        fill-opacity="0.05"
      />
      <path
        d="M16.8948 136C16.8948 122.745 27.6399 112 40.8948 112H328.895C342.15 112 352.895 122.745 352.895 136V219C352.895 232.255 342.15 243 328.895 243H40.8948C27.64 243 16.8948 232.255 16.8948 219V136Z"
        fill="#00A69A"
        fill-opacity="0.05"
      />
      <path
        d="M32.8948 136C32.8948 131.582 36.4765 128 40.8948 128H123.895C128.313 128 131.895 131.582 131.895 136V219C131.895 223.418 128.313 227 123.895 227H40.8948C36.4765 227 32.8948 223.418 32.8948 219V136Z"
        fill="#00A69A"
        fill-opacity="0.05"
      />
      <rect
        x="155.895"
        y="141"
        width="165"
        height="18"
        fill="#00A69A"
        fill-opacity="0.05"
      />
      <rect
        x="155.895"
        y="163"
        width="112.341"
        height="16"
        fill="#00A69A"
        fill-opacity="0.05"
      />
      <rect
        width="60"
        height="24"
        transform="translate(155.895 190)"
        fill="#00A69A"
        fill-opacity="0.05"
      />
    </svg>
  );
}

export default Loading;
