import { useTask } from '@/context/TaskContextProvider';
import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CreateTransactionParticipant from './Participant';

function CreateTransactionParticipants() {
  const {
    state: { formValues },
    dispatch,
  } = useTask();

  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 1,
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      {formValues?.participants &&
        formValues.participants.map((participant: any, index: number) => (
          <CreateTransactionParticipant
            participant={participant}
            key={participant.firstName}
            currentUser={index === 0}
          />
        ))}
      <Box
        sx={{
          width: { xs: '100%', sm: '200px' },
          height: { xs: '138px', sm: '256px' },
        }}
      >
        <Card
          variant="vacant"
          onClick={() => dispatch({ type: 'NEXT', payload: {} })}
        >
          <Add fontSize="large" />
          <Typography variant="h6">
            Add{' '}
            {formValues.transactionType === 'sale' ? ' an owner' : 'a buyer'}
          </Typography>
        </Card>
      </Box>
    </Stack>
  );
}

export default CreateTransactionParticipants;
