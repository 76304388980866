import House from '@/public/house.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import styles from '../addEstateAgent.module.scss';

type Props = {
  meta: any;
  transactionType: 'buy' | 'sell';
  isSelected: boolean;
};
function MetaTransaction({ meta, transactionType, isSelected }: Props) {
  return (
    <Card
      variant="line"
      elevation={isSelected ? 1 : 0}
      sx={!isSelected ? { backgroundColor: 'transparent' } : null}
    >
      <CardMedia className={styles.house} image={House.src} />
      <CardContent>
        <Typography variant="h6">{meta.propertyName}</Typography>
        <Typography variant="body2">
          {meta.propertyTown},{meta.propertyPostcode}
        </Typography>
        <Chip
          label={transactionType === 'buy' ? 'Buying' : 'Selling'}
          color="default"
          size="small"
          variant="filled"
        />
      </CardContent>
    </Card>
  );
}

export default MetaTransaction;
