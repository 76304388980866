'use client';

import { Form } from '@/components/_common/Form/Form';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '@/components/_common/Form/FormTypes';
import { useTask } from '@/context/TaskContextProvider';
import { ChevronLeft, ChevronRight } from '@mui/icons-material/';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  currentUser: any;
};

function CreateTransactionAddParticipant({ currentUser }: Props) {
  const {
    state: { formValues },
    dispatch,
  } = useTask();
  const [values, setValues] = useState<any>({});
  const [showDialog, setShowDialog] = useState(false);
  const [emailConsent, setEmailConsent] = useState(false);

  const components: DynamicFormComponent[] = [
    {
      componentId: 'createTransactionAddParticipantName',
      componentLabel: `What's their name?`,
      componentGuidance: null,
      componentInputs: [
        {
          name: 'firstName',
          type: 'text',
          label: 'First name',
          default_value: '',
          placeholder: 'e.g. Sherlock',
          iconLeft: 'person',
          config: {
            required: { value: true, message: 'Required' },
          },
        },
        {
          name: 'lastName',
          type: 'text',
          label: 'Last name',
          default_value: '',
          placeholder: 'e.g. Homes',
          iconLeft: 'person',
          config: {
            required: { value: true, message: 'Required' },
          },
        },
      ],
    },
    {
      componentId: 'createTransactionAddParticipantDetails',
      componentLabel: `What's their contact details`,
      componentGuidance: null,
      componentInputs: [
        {
          name: 'email',
          type: 'email',
          label: 'Email address',
          default_value: '',
          placeholder: 'e.g. sherlock.homes@kotini.co.uk',
          config: {
            validate: {
              isSameEmail: (email: string) =>
                formValues.participants.every(
                  (participant: any) => participant.email !== email
                ) ||
                `Someone on this transaction is already using this email, for security reasons you can't use shared emails.`,
            },
          },
        },
        {
          name: 'phone',
          type: 'phone',
          label: 'Mobile number',
          default_value: '',
          placeholder: 'e.g. +447712345678',
        },
      ],
    },
  ];

  const footer: DynamicFooterComponent[] = [
    {
      componentId: 'backSubmit',
      componentInputs: [
        {
          name: 'backSubmit',
          type: 'backSubmit',
          label: 'Add',
          rank: 'primary',
          iconRight: 'add',
        },
      ],
    },
  ];

  const handleSubmit = async (formData: any) => {
    if (!formData.email && emailConsent === false) {
      setValues(formData);
      setShowDialog(true);
      return;
    }

    submitForm(formData);
  };

  const handleDialogSubmit = () => {
    setEmailConsent(true);
    setShowDialog(false);
    submitForm(values);
    setValues({});
  };

  const handleBack = async () => {
    dispatch({ type: 'BACK' });
    setValues({});
  };

  const submitForm = async (formData: any) => {
    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        participants: [
          ...formValues.participants,
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            customerId: uuidv4(),
            email: formData.email,
            phone: formData.phone,
            role:
              formValues.transactionType === 'purchase' ? 'buyer' : 'seller',
            inviteEmail: !!formData.email,
            invitePhone: !!formData.phone,
          },
        ],
      },
    });
    dispatch({ type: 'SET_ACTIVE_STEP', payload: 3 });
    setValues({});
  };

  return (
    <>
      <Form
        components={components}
        footerComponents={footer}
        formValues={values}
        onBack={() => handleBack()}
        onSubmit={(formData) => handleSubmit(formData)}
      />
      <Dialog open={showDialog}>
        <DialogTitle>No email address?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Without one, {values.firstName} will need to complete their parts of
            the process in person. This may slow your process down.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            endIcon={<ChevronLeft />}
            onClick={() => {
              setShowDialog(false);
            }}
            data-testid="add"
          >
            Add their email
          </Button>
          <Button
            size="medium"
            color="error"
            endIcon={<ChevronRight />}
            onClick={() => handleDialogSubmit()}
            data-testid="continue"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateTransactionAddParticipant;
